import logoMinimalBlack from '../assets/Humm Logo Black.svg'
import logoMinimalWhite from '../assets/Humm Logo White.svg'
import logoBlack from '../assets/Humm Studios Logo Black.svg'
import logoWhite from '../assets/Humm Studios Logo White.svg'

export const Assets = {
  logoBlack,
  logoWhite,
  logoMinimalBlack,
  logoMinimalWhite,
}
