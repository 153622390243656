import './hummmmm.scss'

import { Arrays } from '@rocketmakers/armstrong-edge'
import * as React from 'react'
import Measure from 'react-measure'

export const Hummmmm: React.FC = () => {
  return (
    <Measure client>
      {({ measureRef, contentRect: { client } }) => {
        const repeats = (client?.width || 0) / 28
        const opacityChange = (0.5 - 1) / (repeats - 5)

        return (
          <div className="hummmmm" ref={measureRef}>
            <div className="hummmmm-inner">
              hu
              {Arrays.repeat(repeats, (index) => (
                <span key={index} className="hummmmm-m" style={{ opacity: 1 + index * opacityChange }}>
                  m
                </span>
              ))}
            </div>
          </div>
        )
      }}
    </Measure>
  )
}
