import './sideTitle.scss'

import * as React from 'react'

// todo - use appropriate configurable tags (h1/h2/h3/span)

export interface ISideTitleProps {}

export const SideTitle: React.FC<React.PropsWithChildren<ISideTitleProps>> = ({ children }) => {
  return (
    <div className="side-title">
      <span>{children}</span>
    </div>
  )
}
