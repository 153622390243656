import './footer.scss'

import * as React from 'react'

import { useFooter } from '../hooks/staticQueries/useFooter'
import { Hummmmm } from './hummmmm'
import { HMRichText } from './richText'

interface IFooterProps {
  showHummmm?: boolean
}

export const Footer: React.FunctionComponent<IFooterProps> = ({ showHummmm }) => {
  const data = useFooter()

  return (
    <footer className="hm-footer">
      {showHummmm && <Hummmmm />}

      <div className="footer-top">
        <div className="footer-left">
          <HMRichText render={data?.left?.richText} />
        </div>
        <div className="footer-right">
          <HMRichText render={data?.right?.richText} />
        </div>
      </div>
      <div className="footer-bottom">
        <HMRichText render={data?.copyright?.richText} />
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  showHummmm: true,
}
