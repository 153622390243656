import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const useCookieBanner = () => {
  const data = useStaticQuery<Queries.UseCookieBannerQuery>(
    graphql`
      query UseCookieBanner {
        prismicCookieBanner {
          data {
            content {
              richText
            }
            accept
            decline
          }
        }
      }
    `
  )

  const mergedData = useMergePrismicPreviewData(data)

  return mergedData?.prismicCookieBanner?.data
}
