import slugify from 'slugify'

export const getSlug = (name: string) => slugify(name, { lower: true })

export namespace Routes {
  export const home = '/'
  export const services = `/services/`
  export const service = (s: Pick<Queries.PrismicService, 'uid'>) => `/services?service=${s.uid}`
  export const blog = (page = 1) => `/blog/${page > 1 ? `/${page}` : ''}`
  export const blogPost = (p: Pick<Queries.PrismicBlogPost, 'uid'>) => `/blog/${p.uid}`
  export const blogPostType = (p: Pick<Queries.PrismicBlogPostType, 'uid'>, page = 1) => `/blog/type/${p.uid}${page > 1 ? `/${page}` : ''}`
  export const about = '/about'
  export const info = (i: Pick<Queries.PrismicInfoPage, 'uid'>) => `/info/${i.uid}`
  export const facilities = '/facilities'
  export const facility = (f: Pick<Queries.PrismicFacility, 'uid'>) => `/facilities?facility=${f.uid}`
  export const book = `/book`
  export const showcase = '/showcase'
}
