import './shell.scss'

import { useLocation } from '@reach/router'
import { useDidUpdateEffect } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { useDisableScroll } from '../hooks/useDisableScroll'
import { CookieBanner } from './cookieBanner'
import { Header } from './header'
import { Meta } from './meta'
import { Nav } from './nav'

export interface IShellProps {
  isHome?: boolean
}

export const Shell: React.FC<React.PropsWithChildren<IShellProps>> = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = React.useState(false)

  useDisableScroll(isNavOpen)

  const { pathname } = useLocation()

  useDidUpdateEffect(() => {
    setIsNavOpen(false)
  }, [pathname])

  return (
    <div className="shell" data-is-nav-open={isNavOpen}>
      <Meta />
      <Header isNavOpen={isNavOpen} setIsNavOpen={setIsNavOpen} />
      <Nav isOpen={isNavOpen} onClickLink={() => setIsNavOpen(false)} />

      <div className="shell-content" onClick={() => isNavOpen && setIsNavOpen(false)} tabIndex={isNavOpen ? -1 : undefined} aria-hidden={isNavOpen}>
        <div className="shell-content-inner">{children}</div>
      </div>

      <CookieBanner />
    </div>
  )
}
