module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"humm-studios","accessToken":"MC5ZdjVMZUJZQUFDa0FHcjda.DO-_ve-_vUJC77-977-9fgNG77-977-977-977-9W--_ve-_ve-_ve-_vQvvv70lR--_vUzvv73vv71d77-977-977-977-9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeLinkHeaders":false,"mergeCachingHeaders":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WKLFBCB0D8"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Humm Studios","short_name":"Humm Studios","start_url":"/","background_color":"#faf8f2","theme_color":"#f2cd4a","display":"standalone","icon":"./src/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af1e9937dd8e200456ad2bf9e3ff6530"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
