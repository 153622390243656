import './cookieBanner.scss'

import { Button, Group } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { useCookieBanner } from '../hooks/staticQueries/useCookieBanner'
import { Analytics } from '../utils/analytics'
import { LocalStore } from '../utils/localStorage'
import { HMRichText } from './richText'

const rejectedStore = new LocalStore<boolean>('DISMISS_COOKIE_BANNER')
const dismissedDateStore = new LocalStore<string>('DISMISS_COOKIE_BANNER_DATE')

const dismissDays = 1

export const CookieBanner: React.FC = () => {
  const data = useCookieBanner()

  const [dismissed, setDismissed] = React.useState(false)

  const dismiss = React.useCallback((disable?: boolean) => {
    setDismissed(true)
    dismissedDateStore.set(new Date().toUTCString())
    if (disable) {
      rejectedStore.set(true)
      Analytics.disable()
    } else {
      rejectedStore.set(false)
    }
  }, [])

  React.useEffect(() => {
    const dismissedDate = dismissedDateStore.get()
    const rejected = rejectedStore.get()

    if (dismissedDate) {
      const currentDate = new Date()
      const compareDate = new Date(currentDate)
      compareDate.setDate(currentDate.getDate() - dismissDays)

      if (new Date(dismissedDate).getTime() > compareDate.getTime()) {
        setDismissed(true)
        if (rejected) {
          Analytics.disable()
        }
      }
    }
  }, [])

  if (dismissed) {
    return null
  }

  return (
    <div className="cookie-banner">
      <HMRichText render={data?.content?.richText} />
      <Group>
        <Button className="decline" onClick={() => dismiss(true)}>
          {data?.decline}
        </Button>
        <Button onClick={() => dismiss()}>{data?.accept}</Button>
      </Group>
    </div>
  )
}
