import './nav.scss'

import { useLocation } from '@reach/router'
import { ClassNames } from '@rocketmakers/armstrong-edge'
import * as React from 'react'

import { useNavigationMenu } from '../hooks/staticQueries/useNavigationMenu'
import { Footer } from './footer'
import { LinkWrapper } from './linkWrapper'

export interface INavProps {
  isOpen: boolean
  onClickLink: () => void
}

export const Nav: React.FC<INavProps> = ({ isOpen, onClickLink }) => {
  // eslint-disable-next-line camelcase
  const data = useNavigationMenu()

  const { pathname, search } = useLocation()

  return (
    <nav data-is-open={isOpen} className="hm-nav" role="menu" id="hm-menu" aria-label="navigation menu">
      <div className="hm-nav-inner">
        {data?.navigation_menu_link?.map((link) =>
          link?.link?.url ? (
            <div className="hm-nav-link-wrapper" key={link.name} onClick={onClickLink} tabIndex={isOpen ? undefined : -1}>
              <LinkWrapper
                to={link.link.url}
                className={ClassNames.concat('hm-nav-link', { 'hm-nav-link-active': link.link.url === pathname + search })}
                role="menuitem"
              >
                {link.name}
              </LinkWrapper>
            </div>
          ) : (
            <div className="hm-nav-section-header" key={link?.name}>
              {link?.name}
            </div>
          )
        )}
      </div>

      <Footer showHummmm={false} />
    </nav>
  )
}
