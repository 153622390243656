exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-views-about-us-tsx": () => import("./../../../src/views/aboutUs.tsx" /* webpackChunkName: "component---src-views-about-us-tsx" */),
  "component---src-views-blog-post-tsx": () => import("./../../../src/views/blogPost.tsx" /* webpackChunkName: "component---src-views-blog-post-tsx" */),
  "component---src-views-blog-tsx": () => import("./../../../src/views/blog.tsx" /* webpackChunkName: "component---src-views-blog-tsx" */),
  "component---src-views-book-tsx": () => import("./../../../src/views/book.tsx" /* webpackChunkName: "component---src-views-book-tsx" */),
  "component---src-views-facilities-tsx": () => import("./../../../src/views/facilities.tsx" /* webpackChunkName: "component---src-views-facilities-tsx" */),
  "component---src-views-home-tsx": () => import("./../../../src/views/home.tsx" /* webpackChunkName: "component---src-views-home-tsx" */),
  "component---src-views-info-tsx": () => import("./../../../src/views/info.tsx" /* webpackChunkName: "component---src-views-info-tsx" */),
  "component---src-views-services-tsx": () => import("./../../../src/views/services.tsx" /* webpackChunkName: "component---src-views-services-tsx" */),
  "component---src-views-showcase-tsx": () => import("./../../../src/views/showcase.tsx" /* webpackChunkName: "component---src-views-showcase-tsx" */)
}

