import './richText.scss'

import { ClassNames } from '@rocketmakers/armstrong-edge'
import { RichText, RichTextProps } from 'prismic-reactjs'
import * as React from 'react'

import { linkResolver } from '../../gatsby/linkResolver'

interface IHMRichTextProps extends RichTextProps {
  className?: string
}

export const HMRichText: React.FunctionComponent<IHMRichTextProps> = ({ className, ...props }) => {
  return (
    <div className={ClassNames.concat('hm-rich-text', className)}>
      <RichText {...props} linkResolver={linkResolver} />
    </div>
  )
}
