import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const useFooter = () => {
  const data = useStaticQuery<Queries.UseFooterQuery>(
    graphql`
      query UseFooter {
        prismicFooter {
          data {
            left {
              richText
            }
            right {
              richText
            }
            copyright {
              richText
            }
          }
        }
      }
    `
  )

  const mergedData = useMergePrismicPreviewData(data)

  return mergedData?.prismicFooter?.data
}
