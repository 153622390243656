import './hamburgerIcon.scss'

import * as React from 'react'

export interface IHamburgerIconProps {
  isOpen: boolean
}

export const HamburgerIcon: React.FC<IHamburgerIconProps> = ({ isOpen }) => {
  return (
    <div className="hamburger-icon" data-is-open={isOpen}>
      <div className="hamburger-icon-inner" />
    </div>
  )
}
