import './header.scss'

import { LinkButton } from '@rocketmakers/armstrong-edge'
import { Link } from 'gatsby'
import * as React from 'react'

import { Assets } from '../content/assets'
import { useNavigationMenu } from '../hooks/staticQueries/useNavigationMenu'
import { Routes } from '../utils/routes'
import { HamburgerIcon } from './hamburgerIcon'
import { SideTitle } from './sideTitle'

export interface IHeaderProps {
  isNavOpen: boolean
  setIsNavOpen: React.Dispatch<React.SetStateAction<boolean>>
}

/** transforms into side bar on desktop */

export const Header: React.FC<IHeaderProps> = ({ isNavOpen, setIsNavOpen }) => {
  const copy = useNavigationMenu()

  return (
    <header className="hm-header" data-is-nav-open={isNavOpen}>
      <div
        className="hm-header-desktop"
        aria-haspopup="menu"
        aria-controls="hm-menu"
        aria-expanded={isNavOpen}
        onClick={() => setIsNavOpen((v) => !v)}
        aria-label={`${isNavOpen ? 'close' : 'open'} menu`}
      >
        <div className="hm-header-top">
          <HamburgerIcon isOpen={isNavOpen} />
          {/* 
          <div className="hm-header-home-link" onClick={(e) => e.stopPropagation()}>
            <LinkButton to={Routes.home}>
              <Home />
            </LinkButton>
          </div> */}
          <div className="hm-header-main-link" onClick={(e) => e.stopPropagation()}>
            {copy?.main_link && <LinkButton to={copy.main_link.url}>{copy.main_link_text}</LinkButton>}
          </div>
        </div>

        <SideTitle>{isNavOpen ? copy?.close : copy?.open}</SideTitle>
      </div>

      <div className="hm-header-mobile">
        <Link to={Routes.home} className="hm-header-mobile-logo">
          <img alt="home" src={Assets.logoBlack} />
        </Link>

        <div className="hm-header-right">
          {copy?.main_link && (
            <div className="hm-header-main-link">
              <LinkButton to={copy.main_link.url}>{copy.main_link_text}</LinkButton>
            </div>
          )}

          <button
            aria-haspopup="menu"
            aria-controls="hm-menu"
            aria-expanded={isNavOpen}
            className="hm-header-nav-button"
            onClick={() => setIsNavOpen((v) => !v)}
            aria-label={`${isNavOpen ? 'close' : 'open'} menu`}
          >
            <HamburgerIcon isOpen={isNavOpen} />
          </button>
        </div>
      </div>
    </header>
  )
}
