import { graphql, useStaticQuery } from 'gatsby'
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

export const useNavigationMenu = () => {
  const data = useStaticQuery<Queries.UseNavigationMenuQuery>(
    graphql`
      query UseNavigationMenu {
        prismicNavigationMenu {
          _previewable
          data {
            open
            close
            navigation_menu_link {
              name
              link {
                url
              }
            }
            main_link {
              url
            }
            main_link_text
          }
        }
      }
    `
  )

  const mergedData = useMergePrismicPreviewData(data)

  return mergedData?.prismicNavigationMenu?.data
}
