import { LinkResolver } from 'prismic-reactjs'

import { Routes } from '../src/utils/routes'

export interface IPrismicDocument {
  type: string
  uid: string
}

// define routes so that links to prismic documents are hooked up

export const linkResolver: LinkResolver = (doc: IPrismicDocument) => {
  switch (doc.type) {
    case 'home_page':
    case 'showcase_item':
      return Routes.home
    case 'services':
      return Routes.services
    case 'service':
      return Routes.service(doc)
    case 'blog':
      return Routes.blog(1)
    case 'blog_post':
      return Routes.blogPost(doc)
    case 'blog_post_type':
      return Routes.blogPostType(doc)
    case 'about_us':
    case 'person':
      return Routes.about
    case 'get_in_touch_form':
      return Routes.book
    case 'info_page':
      return Routes.info(doc)
    case 'facilities':
      return Routes.facilities
    case 'facility':
      return Routes.facility(doc)
    default:
      return Routes.home
  }
}
